import { SpinnerBlock } from "@brutextiles/web-component-library";
import { withAuthentication } from "@containers";
import { useAccountId } from "@recoil"; // change this to hooks
import { PageContext } from "@types";
import { navigate } from "gatsby";
import React, { ReactElement, useEffect, useMemo } from "react";

interface LandingLayout {
    dynamicId?: string;
    pageContext: PageContext;
}

const Landing = ({ pageContext, dynamicId }: LandingLayout): ReactElement => {
    const accountId = useAccountId();
    const dynamicSlug = useMemo(
        () => pageContext.dynamicSlug,
        [pageContext.dynamicSlug],
    );
    const accountUriPrefix = useMemo(
        () => pageContext.accountUriPrefix,
        [pageContext.accountUriPrefix],
    );
    useEffect(() => {
        if (accountId) {
            navigate(
                `/en${
                    accountUriPrefix
                        ? `/account/${accountId.toLowerCase()}`
                        : ""
                }${dynamicSlug.replace(":dynamicId", dynamicId)}${
                    location.search
                }`,
            );
        }
    }, [accountId, dynamicSlug, dynamicId, accountUriPrefix]);

    return <SpinnerBlock fullscreen variant={"secondary"} />;
};

export default withAuthentication(Landing);
